$items: 4;
$transition-duration: 0.5s;
$transition-delay: 0.05s;

#toggle {
  display: none; //hides the checkbox
}

.mobile-menu {
  display: none;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;

  & ~ .navbar-brand {
    position: absolute;
    right: 0;
  }
  // Styles for the 'open' state, if the checkbox is checked
  #toggle:checked {
    //Any element you need to change the style if menu is open goes here, using the sibling selector (~)
    & ~ .toggle-container .button-toggle {
      box-shadow: 0 0 0 1100px rgba(230, 87, 89, 1), inset 0 0 0 20px rgba(230, 87, 89, 1);

      &:before {
        transform: translateY(-50%) rotate(45deg) scale(1);
      }

      &:after {
        transform: translateY(-50%) rotate(-45deg) scale(1);
      }
    }

    // Open Nav
    & ~ .nav {
      pointer-events: auto;
      position: absolute;
      top: 50%;
      left: 23%;
      transform: translate(50%,-50%);
      margin: 0;

      // Restoring nav items from 'lines' in the menu icon
      .nav-item {
        color: #fff;
        letter-spacing: 0;
        height: 40px;
        line-height: 40px;
        margin-top: 0;
        opacity: 1;
        transform: scaleY(1);
        transition: $transition-duration, opacity 0.1s;
        &:hover {
          text-decoration: none;
          opacity: 0.8;
        }

        // Setting delays for the nav items in open transition
        @for $i from 1 through $items {
          &:nth-child(#{$i}) {
            $delay: ($items - $i) * $transition-delay;
            transition-delay: $delay;
            &:before {
              transition-delay: $delay;
            }
          }
        }

        // Hide the lines
        &:before {
          opacity: 0;
        }
      }
    }
  }

  /* Toggle Button */
  .button-toggle {
    position: absolute;
    display: inline-block;
    width: 25px;
    height: 20px;
    margin: 25px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 100%;
    transition: $transition-duration + 0.1;

    // Shadow on Hover
    &:hover {
     box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.1), inset 0 0 0 20px rgba(0, 0, 0, 0.1);
    }

    // Making the 'X' icon using `:before` and `:after` pseudo-elements
    // Initially hidden beause of `scale(0)` transformation

    &:before, &:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #fff;
      border-radius: 5px;
      transition: $transition-duration;
    }

    &:before {
      transform: translateY(-50%) rotate(45deg) scale(0);
    }

    &:after {
      transform: translateY(50%) rotate(-45deg) scale(0);
    }
  }

  /* Menu */

  .nav {
    display: inline-block;
    margin: 25px 25px 20px;
    pointer-events: none;
    transition: $transition-duration;
    position: absolute;
    top: 0%;
    left: 0%;
    transform: translate(0%, -0%);
  }

  // Showing nav items as lines, making up the hamburger menu icon
  .nav-item {
    position: relative;
    display: inline-block;
    float: left;
    clear: both;
    color: transparent;
    font-size: 14px;
    letter-spacing: -6.2px;
    height: 7px;
    line-height: 7px;
    text-transform: uppercase;
    white-space: nowrap;
    transform: scaleY(0.2);
    transition: $transition-duration, opacity 1s;

    //Set delays for the nav items in close transition
    @for $i from 1 through $items {
      &:nth-child(#{$i}) {
        $delay: ($i - 1) * $transition-delay;
        transition-delay: $delay;
        &:before {
          transition-delay: $delay;
        }
      }
    }

    // Adjusting width for first line
    &:nth-child(1) {
      letter-spacing: -5px;
    }

    // Adjusting width for second line
    &:nth-child(2) {
      letter-spacing: -6px;
    }

    // Adjusting the fourth element onwards
    &:nth-child(n + 4) {
      letter-spacing: -8px;
      margin-top: -7px;
      opacity: 0;
    }

    //getting lines for the hamburger menu icon
    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #fff;
      transform: translateY(-50%) scaleY(5);
      transition: $transition-duration;
    }
  }
}


@media (max-width: 991px) {
  .navbar-dark {
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
    height: 70px;
    padding: 0 !important;
    margin-bottom: 0 !important;
    background: #19B5C2;
    >.container {
      height: 100%;
    }
    &.mobile {
      height: 100%;
    }
  }
  .navbar-brand {
    position: absolute;
    top: 15px;
    right: 0;
    z-index: 1;
  }
  .mobile-menu {
    display: flex;
  }
}
