.react-datepicker .react-datepicker-container .react-datepicker-top {
    background: #E65759!important;
    border-top: 1px solid #E65759!important;
    border-left: 1px solid #E65759!important;
    border-right: 1px solid #E65759!important;
}

.react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker.selected.current {
    background: #E65759!important;
    color: #ffffff;
}

.react-datepicker-component .react-datepicker-input.is-open {
    border-color: #E65759!important;
}
