.wave {
    background: url('/images/wave.svg') repeat-x;
    bottom: 0;
    width: 6400px;
    height: 198px;
    animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
}

.wave.white {
    background: url('/images/wave-white.svg') repeat-x;
}

@keyframes wave {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1600px;
    }
}
