.rc-slider-track {
    background-color: #E67D80;
}
.rc-slider-handle {
    border: solid 2px #E67D80;
}
.rc-slider-handle:focus {
    border-color: #E65759;
    box-shadow: 0 0 0 5px #E67D80;
}
.rc-slider-handle-click-focused:focus {
    border-color: #E67D80;
}
.rc-slider-handle:hover {
    border-color: #E65759;
}
.rc-slider-handle:active {
    border-color: #E65759;
    box-shadow: 0 0 5px #E65759;
}
.rc-slider-dot-active {
    border-color: #E67D80;
}
